var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[(_vm.resolutionScreen >= 500)?_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"600px"},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("fileduplicate.headerfileduplicate"))),_c('v-spacer'),_c('v-icon',{attrs:{"color":_vm.color.theme,"dense":"","dark":"","size":"35"}},[_vm._v("mdi-progress-upload")])],1),_c('v-divider'),(_vm.listfileduplicate.length > 0)?_c('v-card-text',{staticClass:"pa-0 mt-1",staticStyle:{"height":"400px"}},[_c('div',_vm._l((_vm.listfileduplicate),function(item,i){return _c('v-list',{key:i},[_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{staticClass:"pl-1",attrs:{"value":item},model:{value:(_vm.selectedduplicate),callback:function ($$v) {_vm.selectedduplicate=$$v},expression:"selectedduplicate"}})],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.listfileduplicate[i].icon[1],"large":""}},[_vm._v(_vm._s(_vm.listfileduplicate[i].icon[0]))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-4"},[_vm._v(" "+_vm._s(_vm.listfileduplicate[i].name)+" ")])],1)],1)],1)}),1)]):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-checkbox',{staticClass:"pt-5 mt-n2 mb-n2",attrs:{"label":_vm.$t('fileduplicate.selectall')},model:{value:(_vm.selectedAll),callback:function ($$v) {_vm.selectedAll=$$v},expression:"selectedAll"}}),_c('v-spacer'),(_vm.uploadfolder !== 'true')?_c('v-btn',{attrs:{"disabled":_vm.selectedduplicate.filter((item) => item.type === 'folder').length !==
              0 || _vm.selectedduplicate.length === 0,"outlined":"","color":"primary"},on:{"click":function($event){_vm.$emit('replace', _vm.selectedduplicate, 'create_tag_version'),
              (_vm.selectedduplicate = [])}}},[_c('span',[_vm._v(_vm._s(_vm.$t("fileduplicate.filereplace")))])]):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.selectedduplicate.length === 0,"outlined":"","color":"success"},on:{"click":function($event){_vm.$emit('copy', _vm.selectedduplicate, 'create_copy'),
              (_vm.selectedduplicate = [])}}},[_c('span',[_vm._v(_vm._s(_vm.$t("fileduplicate.filecopy")))])]),_c('v-btn',{attrs:{"disabled":_vm.selectedduplicate.length === 0,"outlined":"","color":"error"},on:{"click":function($event){_vm.$emit('skip', _vm.selectedduplicate, ''),
              (_vm.selectedduplicate = [])}}},[_c('span',[_vm._v(_vm._s(_vm.$t("fileduplicate.fileskip")))])])],1)],1)],1):_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":_vm.maxWidthOnMobile,"content-class":"rounded-lg"},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-layout',{staticClass:"pt-2 pb-n1"},[_c('v-flex',{attrs:{"d-flex":"","justify-center":""}},[_c('span',{style:(_vm.headerPage)},[_vm._v(_vm._s(_vm.$t("fileduplicate.headerfileduplicate")))])])],1)],1),(_vm.listfileduplicate.length > 0)?_c('v-card-text',{staticClass:"pa-0 mt-1",staticStyle:{"height":"400px"}},[_c('div',_vm._l((_vm.listfileduplicate),function(item,i){return _c('v-list',{key:i},[_c('v-list-item',{staticClass:"mt-n4"},[_c('v-list-item-action',[_c('v-icon',{staticClass:"pl-2",attrs:{"color":_vm.listfileduplicate[i].icon[1],"large":""}},[_vm._v(_vm._s(_vm.listfileduplicate[i].icon[0]))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.listfileduplicate[i].name)+" ")])],1)],1)],1)}),1)]):_vm._e(),_c('div',{staticClass:"text-center my-4"},[(_vm.uploadfolder !== 'true')?_c('v-btn',{staticClass:"mr-2",style:(_vm.btnAction),attrs:{"disabled":_vm.selectedduplicate.filter((item) => item.type === 'folder').length !==
              0 || _vm.selectedduplicate.length === 0,"width":"28%","height":"40px","outlined":"","color":"primary"},on:{"click":function($event){_vm.$emit('replace', _vm.selectedduplicate, 'create_tag_version'),
              (_vm.selectedduplicate = [])}}},[_c('span',[_vm._v(_vm._s(_vm.$t("fileduplicate.filereplace")))])]):_vm._e(),_c('v-btn',{staticClass:"mr-2",style:(_vm.btnAction),attrs:{"disabled":_vm.selectedduplicate.length === 0,"width":"29%","height":"40px","outlined":"","color":"success"},on:{"click":function($event){_vm.$emit('copy', _vm.selectedduplicate, 'create_copy'),
              (_vm.selectedduplicate = [])}}},[_c('span',[_vm._v(_vm._s(_vm.$t("fileduplicate.filecopy")))])]),_c('v-btn',{style:(_vm.btnAction),attrs:{"disabled":_vm.selectedduplicate.length === 0,"width":"28%","height":"40px","outlined":"","color":"error"},on:{"click":function($event){_vm.$emit('skip', _vm.selectedduplicate, ''),
              (_vm.selectedduplicate = [])}}},[_c('span',[_vm._v(_vm._s(_vm.$t("fileduplicate.fileskip")))])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }